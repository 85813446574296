<!--
 * @Author: zl
 * @Date: 2022-02-24 15:22:18
 * @LastEditTime: 2022-03-04 14:25:04
 * @LastEditors: zl
 * @Description: 审计意见单
-->
<template>
	<div class="AuditOpinion-check-layout">
		<div class="auditArea"
			 v-show="auditVisble">
			<el-button size="mini"
					   type="primary"
					   v-show="routeQuery == 'audit'"
					   @click="auditObj.show = true">审核</el-button>
			<el-button size="mini"
					   v-if="routeQuery == 'audit'"
					   @click="backApproval">取消</el-button>
		</div>
		<el-collapse v-model="activeNames">
			<el-collapse-item title="#1 审计意见单信息"
							  name="1">
				<el-row class="view-form">
					<el-col :span="24">
						<el-col :span="4">工程项目名称</el-col>
						<el-col :span="20">
							<span @click="viewProject"
								  :class="{ 'blue-text': opinionForm.projectName != '' }">
								{{ opinionForm.projectName || '无' }}
							</span>
						</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">关联审计任务任务</el-col>
						<el-col :span="20">{{ opinionForm.taskName || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">被审计（调查）单位或个人</el-col>
						<el-col :span="20">{{ opinionForm.beAuditer	 || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">审计（调查）事项</el-col>
						<el-col :span="20">{{ opinionForm.auditMatters || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">审计（调查）事项摘要</el-col>
						<el-col :span="20">{{ opinionForm.content || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">上传附件</el-col>
						<el-col :span="20">
							<template v-for="item in fileList">
								<FileName :fileName="item.fileOrignName"
										  :filePath="item.fileDirectory + item.fileOrignName"
										  :fileSize="item.fileSize"
										  class="mr10"></FileName>
							</template>
						</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">审计人员</el-col>
						<el-col :span="8">{{ opinionForm.auditerName || '无' }}</el-col>
						<el-col :span="4">编制日期</el-col>
						<el-col :span="8">
							{{ opinionForm.redactDate ? opinionForm.redactDate.substr(0, 11) : '无' }}
						</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">建设单位意见</el-col>
						<el-col :span="8">{{ opinionForm.consContent || '无' }}</el-col>
						<el-col :span="4">监理单位意见</el-col>
						<el-col :span="8">{{ opinionForm.supContent || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">建设单位</el-col>
						<el-col :span="8">{{ opinionForm.ownerUnitName || '无' }}</el-col>
						<el-col :span="4">监理单位</el-col>
						<el-col :span="8">{{ opinionForm.supUnit || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">建设单位负责人</el-col>
						<el-col :span="8">{{ opinionForm.consPrincipal || '无' }}</el-col>
						<el-col :span="4">监理单位负责人</el-col>
						<el-col :span="8">{{ opinionForm.supPrincipal || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">签收日期</el-col>
						<el-col :span="8">
							{{ opinionForm.consSignDate ? opinionForm.consSignDate.substr(0, 11) : '无' }}
						</el-col>
						<el-col :span="4">签收日期</el-col>
						<el-col :span="8">
							{{ opinionForm.supSignDate ? opinionForm.supSignDate.substr(0, 11) : '无' }}
						</el-col>
					</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">施工单位意见</el-col>
						<el-col :span="8">{{ opinionForm.roadContent || '无' }}</el-col>
						<el-col :span="4">其他单位意见</el-col>
						<el-col :span="8">{{ opinionForm.otherContent || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">施工单位</el-col>
						<el-col :span="8">{{ opinionForm.roadUnit || '无' }}</el-col>
						<el-col :span="4">其他单位</el-col>
						<el-col :span="8">{{ opinionForm.otherUnit || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">施工单位负责人</el-col>
						<el-col :span="8">{{ opinionForm.roadPrincipal || '无' }}</el-col>
						<el-col :span="4">其他单位负责人</el-col>
						<el-col :span="8">{{ opinionForm.otherPrincipal || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">签收日期</el-col>
						<el-col :span="8">
							{{ opinionForm.roadSignDate ? opinionForm.supSignDate.substr(0, 11) : '无' }}
						</el-col>
						<el-col :span="4">签收日期</el-col>
						<el-col :span="8">
							{{ opinionForm.otherSignDate ? opinionForm.otherSignDate.substr(0, 11) : '无' }}
						</el-col>
					</el-col>
				</el-row>
			</el-collapse-item>
			<el-collapse-item title="#2 申请单位信息"
							  name="2">
				<el-row class="view-form">
					<el-col :span="24">
						<el-col :span="4">申请人</el-col>
						<el-col :span="8"
								class="ml0">{{ applyObj.createUserName || '无' }}</el-col>
						<el-col :span="4">申请单位</el-col>
						<el-col :span="8"
								class="ml0">{{ applyObj.sponsorUnitShortName || '无' }}</el-col>
					</el-col>
					<el-col :span="24">
						<el-col :span="4">申请日期</el-col>
						<el-col :span="20">
							{{ applyObj.createDate || '无' }}
						</el-col>
					</el-col>
				</el-row>
			</el-collapse-item>
			<el-collapse-item title="#3 审核信息"
							  name="3">
				<vxe-table align="center"
						   auto-resize
						   border
						   :data="checkData"
						   key="10002"
						   highlight-current-row
						   highlight-hover-row
						   show-overflow="tooltip"
						   show-header-overflow="tooltip">
					<vxe-table-column field="userName"
									  title="审批人/移交人"></vxe-table-column>
					<vxe-table-column field="shortName"
									  title="审批单位"
									  header-align="center"
									  align="left">
						<template #default="{ row }">
							<el-tooltip effect="dark"
										:content="row.deptName"
										placement="top">
								<span class="vxe-cell--label">{{ row.shortName }}</span>
							</el-tooltip>
						</template>
					</vxe-table-column>
					<vxe-table-column field="handleDate"
									  title="审批时间/移交时间"></vxe-table-column>
					<vxe-table-column field="status"
									  title="审批结果">
						<template #default="{ row }">
							<span v-if="row.status == '1001026002'">通过</span>
							<span v-else-if="row.status == '1001026003'">驳回</span>
							<span v-else>-</span>
						</template>
					</vxe-table-column>
					<vxe-table-column field="remark"
									  title="审批意见"
									  header-align="center"
									  align="left"></vxe-table-column>
					<vxe-table-column field=""
									  title="备注">
						<template #default="{ row }">
							<span v-if="row.status == '1001026041'">移交</span>
							<span v-else>-</span>
						</template>
					</vxe-table-column>
				</vxe-table>
			</el-collapse-item>
		</el-collapse>

		<!-- 审核弹窗 -->
		<el-dialog :title="auditObj.title"
				   :visible.sync="auditObj.show"
				   center
				   :before-close="auditCancel"
				   append-to-body
				   :close-on-click-modal="false"
				   :width="auditObj.width">
			<el-form :model="auditObj"
					 label-width="120px"
					 ref="auditForm"
					 size="small">
				<el-form-item label="审核结果"
							  prop="status"
							  :rules="requireRule">
					<el-radio-group v-model="auditObj.status"
									@change="radioChange">
						<el-radio label="1001026002">通过</el-radio>
						<el-radio label="1001026003">驳回</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="审核意见"
							  prop="remark"
							  :rules="auditObj.status == '1001026003' ? requireRule : []"
							  class="mb30">
					<el-input type="textarea"
							  :autosize="{ minRows: 6 }"
							  :maxlength="200"
							  show-word-limit
							  placeholder="请输入内容"
							  v-model="auditObj.remark">
					</el-input>
				</el-form-item>
				<el-form-item>
					<div class="tip-box">
						<p><i class="el-icon-warning"></i>备注：驳回，必须在审核意见内填写驳回理由。</p>
						<p class="left-indent">审核结束：该审批流程到此结束。</p>
						<p class="left-indent">继续提交审核：继续为该审批流程选择下一步的提交审核对象。</p>
					</div>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button class="btn"
						   @click="auditCancel"
						   size="small">取消</el-button>
				<el-button class="btn btn-success"
						   size="small"
						   @click="auditConfirm"
						   :loading="auditObj.loading">审核结束</el-button>
				<el-button class="btn"
						   type="primary"
						   size="small"
						   :disabled="auditObj.status == '1001026003'"
						   @click="handOther"
						   :loading="auditObj.loading">继续提交审核</el-button>
			</div>
		</el-dialog>

		<!-- 查看项目信息 -->
		<el-dialog title="查看项目"
				   :visible.sync="projectObjShow"
				   width="1200px"
				   :close-on-click-modal="false"
				   top="4vh"
				   center>
			<ProjectInfoLook v-if="projectObjShow"
							 v-loading="projectLoading"
							 ref="ProjectInfoLook"
							 :noRoute="true"></ProjectInfoLook>
		</el-dialog>

		<!-- 移交他人审核 -->
		<AuditPersonList v-if="auditShow"
						 ref="AuditPersonList"
						 :rowData="currentRow"
						 doType="audit"
						 :auditForm="auditObj"
						 @closeDia="closeDia"></AuditPersonList>
	</div>
</template>
<script>
import { getSubmitAdd, getApprovalReportOpinion } from 'api/approval/approval'
import ProjectInfoLook from '@/views/Common/ProjectInfoLook'
import fileName from '@/components/file/fileName'
import AuditPersonList from '@/views/Common/AuditPersonList'
import * as ApprovalApi from 'api/approval/approval'
export default {
	name: 'AuditOpinion',
	components: { ProjectInfoLook, fileName, AuditPersonList },
	props: {},
	data() {
		return {
			activeNames: ['1', '2', '3'],
			// 弹窗对象
			auditObj: {
				status: '1001026002',
				remark: '',
				title: '审核',
				show: false,
				width: '1000px',
				loading: false,
			},
			opinionForm: {},
			checkData: [], //审核记录
			fileList: [], //附件
			projectObjShow: false,
			projectLoading: false,
			auditShow: false,
			requireRule: [{ required: true, message: '不能为空...' }],
			applyObj: {},
		}
	},
	created() {
		//审计方案详情获取
		this.getApprovalReportOpinion()
		// 获取审批信息
		this.getApprovalList()
	},
	methods: {
		// 移交其他人员
		handOther() {
			this.auditShow = true

			this.$refs.auditForm.validate((valid) => {
				if (valid) {
					setTimeout(() => {
						this.$refs.AuditPersonList.auditPersonObj.show = true
					}, 200)
					return
				}
				this.$message.error('请完善表单！')
			})
		},

		// 接收弹窗关闭
		closeDia(data) {
			this.auditShow = false
			if (data) {
				this.backApproval()
			}
		},

		// change事件
		radioChange(data) {
			setTimeout(() => {
				this.$refs.auditForm.clearValidate()
			}, 100)
		},

		// 获取审批信息
		getApprovalList() {
			ApprovalApi.selectApproveRecordById({
				id: this.currentRow.id,
				module: 2,
			}).then((res) => {
				this.checkData = res.data
			})
		},

		//审计方案详情获取
		getApprovalReportOpinion() {
			getApprovalReportOpinion({
				recordId: this.currentRow.id,
			}).then((res) => {
				this.opinionForm = res.data.viewBill
				this.applyObj = res.data.approveRecord
				this.fileList = res.data.fileList
			})
		},

		//审核提交
		auditConfirm() {
			let params = this.$utils.clone(this.auditObj, true)
			this.$refs.auditForm.validate((valid) => {
				if (valid) {
					this.auditObj.loading = true
					// 需要带上数据的 busId
					params.busId = this.currentRow.busId
					getSubmitAdd(params).then((res) => {
						this.auditObj.loading = false
						this.backApproval()
					})
					return
				}
				this.$message.error('请完善表单！')
			})
		},

		//审核取消
		auditCancel() {
			this.auditObj.show = false
			this.$refs.auditForm.resetFields()
		},

		// 返回
		backApproval() {
			this.$router.push({ path: '/redirect/approval' })
		},

		// 查看项目信息
		viewProject() {
			if (this.opinionForm.projectName == '') {
				return
			}
			this.projectObjShow = true
			this.projectLoading = true
			setTimeout(() => {
				this.$refs.ProjectInfoLook.getInfoData(
					this.opinionForm.projectId
				)
			}, 300)
			setTimeout(() => {
				this.projectLoading = false
			}, 900)
		},
	},
	computed: {
		// 获取路由里的type
		routeQuery() {
			return this.$route.query.type
		},

		//审核按钮可见
		auditVisble() {
			return this.$route.query.menu === 'project'
		},

		// 缓存的当前行对象
		currentRow() {
			return this.$getSessionItem('currentRow')
				? JSON.parse(this.$getSessionItem('currentRow'))
				: {}
		},
	},
	beforeRouteLeave(to, from, next) {
		this.$getSessionItem('currentRow')
			? this.$removeSessionItem('currentRow')
			: ''
		if (
			to.path == '/redirect/auditTask' ||
			to.path == '/redirect/auditManagement' ||
			to.path == '/redirect/approval'
		) {
		} else {
			this.$getSessionItem('activeName')
				? this.$removeSessionItem('activeName')
				: ''
		}
		next()
	},
}
</script>
<style lang='less' scoped>
.AuditOpinion-check-layout {
	padding: 20px;
	overflow-y: auto;
	height: 100%;
	.auditArea {
		position: absolute;
		right: 10px;
		top: 65px;
		height: 30px;
		background-color: white;
	}
	.el-collapse {
		border-bottom: none;
		.el-collapse-item {
			margin-bottom: 15px;
		}
		/deep/ .el-collapse-item__header {
			padding-left: 15px;
			font-size: 16px;
			background-color: #eff6fe;
		}
		/deep/ .el-collapse-item__wrap {
			border-bottom: none;
		}
		/deep/ .el-collapse-item__content {
			padding: 15px;
		}
	}
	.view-form {
		.el-col {
			padding: 10px !important;
			border: 2px solid #eee;
			margin-top: -1px;
			margin-left: -2px;
			word-break: break-all;
		}
		.el-col-24 {
			display: flex;
			padding: 0 !important;
			border: none !important;
		}
		.el-col-4 {
			text-align: right;
			background-color: #f7f7f7;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
	.ml0 {
		margin-left: 0 !important;
		border-left: none !important;
	}
}
.btn {
	padding: 0 50px !important;
}
.tip-box {
	line-height: 25px;
	background: #ecf5ff;
	border-radius: 5px;
	border: 1px solid #d9ecff;
	color: #419eff;
	padding: 5px 15px;
	.left-indent {
		text-indent: 3.5rem;
	}
}
</style>

